import React from "react";
import Typography from "@mui/material/Typography";
// import Select from "@mui/material/Select";

import Select from "react-select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";
import { COLORS } from "../../constants/theme";
function CustomDropdown({
  Options,
  Value,
  OnChange,
  Label,
  error,
  menuPlacement,
}) {
  return (
    <Box>
      <Select
        classNames={{
          control: (state) => (state.isFocused ? "text-error" : "text-error"),
        }}
        // styles={{
        //   control: (baseStyles, state) => ({
        //     ...baseStyles,

        //     // display:"flex",
        //     // borderColor: state.isFocused ? "#637091" : "lightgray",
        //     // boxShadow: error ? "0 0 5px red" : "",
        //     // borderColor: error ? COLORS.red : "#637091",
        //     height: 45,
        //     borderRadius: 10,
        //     // border: error ? 1 : 0,
        //     borderWidth: 1,
        //     background: "#f9fffe",
        //     ":hover": {
        //       borderColor: error ? COLORS.red : null,
        //     }, 
        //     ":focus": {
        //       borderColor: "red",
        //     },
        //   }),
        // }}
        className="text-error"
        options={Options}
        value={Value}
        onChange={OnChange}
        menuPlacement={menuPlacement}
        // placeholder={"Select " + Label}
        styles={{
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: "#94a3b8",
              fontSize: 15,
              fontWeight: 450,
              marginTop: -5,
            };
          },
          control: (provided, state) => ({
            ...provided,
            background: "#fff",
            borderColor: "#c4c5c6",
            minHeight: "30px",
            height: "30px",
            borderRadius: 5,

            background: "#fdfdfe",
            boxShadow: state.isFocused ? "0 0 5px #f9fbfd" : "",
          }),

          valueContainer: (provided, state) => ({
            ...provided,
            height: "30px",
            padding: "0 6px",
          }),

          input: (provided, state) => ({
            ...provided,
            margin: "0px",
          }),
          indicatorSeparator: (state) => ({
            display: "none",
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "30px",
          }),
        }}
        // styles={{
        //   placeholder: (defaultStyles) => {
        //     return {
        //       ...defaultStyles,
        //       color: "#94a3b8",
        //       fontSize: 14,
        //     };
        //   },
        //   control: (baseStyles, state) => ({
        //     ...baseStyles,
        //     borderColor: state.isFocused ? "lightgray" : "lightgray",
        //     boxShadow: state.isFocused ? "0 0 5px #f9fbfd" : "",
        //     height: "25px",
        //     borderRadius: 5,
        //     // border: error ? 1 : 0,
        //     borderWidth: 1,
        //     background: "#f9fbfd",
        //     // color:"red"
        //   }),
        //   valueContainer: (provided, state) => ({
        //     ...provided,
        //     height: "25px",
        //     padding: "0 6px",
        //   }),
        //   base: (base) => ({
        //     ...base,
        //     fontFamily: "Times New Roman",
        //     color: "red",
        //   }),
        //   input: (provided, state) => ({
        //     ...provided,
        //     margin: "0px",
        //   }),
        //   indicatorSeparator: (state) => ({
        //     display: "none",
        //   }),
        //   indicatorsContainer: (provided, state) => ({
        //     ...provided,
        //     height: "25px",
        //   }),
        //   option: (baseStyles, state) => ({
        //     ...baseStyles,
        //     backgroundColor: state.isSelected ? "#22728d" : "white",
        //     color: state.isSelected ? "white" : "black",
        //     ":hover": {
        //       backgroundColor: "#f9fbfd",
        //       color: "black",
        //     },
        //   }),
        // }}
      />
    </Box>
  );
}

export default CustomDropdown;
