import { useEffect } from "react";
import { connect } from "react-redux";
import { ContactDataGetAction } from "../Redux/Action/ContactDataGetAction";
import { MeetingDataGetAction } from "../Redux/Action/MeetingDataGetAction";
import { MailHistoryGetAction } from "../Redux/Action/MailHistoryGetAction";
import { EmployeeDetailsGetAction } from "../Redux/Action/EmployeeDetailsGetAction";
import { DdOptionsGetAction } from "../Redux/Action/DdOptionsGetAction";
import { AuthAction } from "../Redux/Action/AuthAction";

const RenderDataOnLoad = (props) => {
  useEffect(() => {
    props.ContactDataGetAction();
    props.MeetingDataGetAction();
    props.MailHistoryGetAction();
    props.EmployeeDetailsGetAction();
    props.DdOptionsGetAction();
    props.AuthAction();
  }, []);
};

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
});

// export default RenderDataOnLoad;
export default connect(mapStateToProps, {
  ContactDataGetAction,
  MeetingDataGetAction,
  MailHistoryGetAction,
  EmployeeDetailsGetAction,
  DdOptionsGetAction,
  AuthAction,
})(RenderDataOnLoad);
