import axios from "axios";
import getAccessToken from "../../constants/getAccessToken";
import clientData from "../../constants/clientData";
import { AxiosDefaultPort } from "../../constants/Axios";

export const MailHistoryGetAction = () => (dispatch) => {
  const getSharepointData = (token) => {
    axios
      .post(
        // `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('mailHistory')/items`,
        `${AxiosDefaultPort}apis/sharepoint/mailHistoryGet`,
        { token }

        // {
        //   headers: {
        //     Authorization: "Bearer " + token,
        //   },
        // }
      )
      .then((response) => {
        dispatch({
          type: "MAIL_HISTORY_GET",
          payload: response.data.value,
        });
      });
  };
  getAccessToken(getSharepointData);
};
