import axios from "axios";
import getAccessToken from "../../constants/getAccessToken";
import clientData from "../../constants/clientData";
import { AxiosDefaultPort } from "../../constants/Axios";

export const ContactDataGetAction = () => (dispatch) => {
  const getSharepointData = (token) => {
    axios
      .post(
        `${AxiosDefaultPort}apis/sharepoint/contactDataGet`,
        { token }
      )
      .then((response) => {
        let temp = [...response.data.value];
        temp = temp.filter((val) => val.isRemoved == "No");
        temp.map((val) => {
          val.checked = false;
          val.ID = val.ID.toString();
        });
       
        dispatch({
          type: "CONTACT_DATA_GET",
          payload: temp,
        });
      });
  };
  getAccessToken(getSharepointData);
};
