import React from "react";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
} from "@mui/material";

function CustomButton({
  Label,
  style,
  onClick,
  buttonColor,
  labelStyle,
}) {
  return (
    <button
      class="save-button custom-save-button"
      onClick={onClick}
      style={{
        ...style,
        backgroundColor: buttonColor,
        borderColor: buttonColor,
        WebkitBoxShadow: "rgba(0,0,0,0.5) 4px 0 10px",
        MozBoxShadow: "rgba(0,0,0,0.5) 4 4 10px",
        boxShadow: "rgba(0,0,0,0.5) 4 4 10px",
      }}
    >
      <i className="animation"></i>
      <Typography style={{ ...labelStyle }}>{Label}</Typography>
      <i className="animation"></i>
    </button>
    // <Box
    //   style={{
    //     ...style,
    //     backgroundColor: buttonColor,
    //     borderColor: buttonColor,
    //     WebkitBoxShadow: "rgba(0,0,0,0.5) 4px 0 10px",
    //     MozBoxShadow: "rgba(0,0,0,0.5) 4 4 10px",
    //     boxShadow: "rgba(0,0,0,0.5) 4 4 10px",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    //   <button
    //     className="AddButton"
    //     // class="save-button custom-save-button"
    //     onClick={onClick}
    //     style={{
    //       width: "100%",
    //       cursor: "pointer",
    //       borderRadius: style.borderRadius ? style.borderRadius : 0,
    //       backgroundColor: buttonColor,
    //       borderColor: buttonColor,
    //       borderWidth: "0px",
    //     }}
    //   >
    //       <Typography style={labelStyle}>{Label}</Typography>

    //   </button>
    // </Box>
  );
}

export default CustomButton;
