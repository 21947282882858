import React from "react";
import Lottie from "react-lottie-player";
import notFound from "../assets/lottieFiles/not_found.json";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
} from "@mui/material";

function Page404() {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        play
        //   speed={"1"}
        animationData={notFound}
        style={{
          width: 300,
          height: 300,
        }}
      />
      <Typography sx={{ fontSize: 20, fontWeight: "bold", color: "grey" }}>
        Please contact Admin
      </Typography>
    </Box>
  );
}

export default Page404;
