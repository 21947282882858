import logo from "./logo.svg";
import { Box } from "@mui/material";
import "./App.css";
import { Brow } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom/dist";
import ContactCapture from "./screens/ContactCapture";
import ScanText from "./screens/ScanText";
import SearchPage from "./screens/SearchPage";
import ContactDetails from "./screens/ContactDetails";
import CompanyView from "./screens/CompanyView";
import Dashboard from "./screens/Dashboard";
import SendEmail from "./screens/SendEmail";
import DashboardC from "./screens/DashboardC";
import Testing from "./screens/Testing";
import MailHistory from "./screens/MailHistory";
import AdminPanel from "./screens/AdminPanel";
import Page404 from "./screens/Page404";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ContactDataGetAction } from "./Redux/Action/ContactDataGetAction";
import { MeetingDataGetAction } from "./Redux/Action/MeetingDataGetAction";
import { EmployeeDetailsGetAction } from "./Redux/Action/EmployeeDetailsGetAction";
import Loader from "./screens/Loader";
import Lottie from "react-lottie-player";
import loadingAnime from "./assets/lottieFiles/loader.json";
import Login from "./screens/Login";
import axios from "./components/Axios/axios";
import { AxiosDefaultPort } from "./constants/Axios";
function App(props) {
  const [empExist, setEmpExist] = useState("NODATA");
  // const [empExist, setEmpExist] = useState(null);
  const [role, setRole] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const SessionToken = window.localStorage.getItem("CMS_STOKEN");
    console.log("ASDasdasdasd", SessionToken);
    if (SessionToken) {
      handleSessionVerify(SessionToken);
    }
  }, []);

  const handleSessionVerify = (SessionID) => {
    axios
      .post(AxiosDefaultPort + "apis/Login/sessionTokenVerify", {
        SESSION_TOKEN: SessionID,
      })
      .then((response) => {
        console.log("ASdadasdas", response);
        if (response.otpMatch == true) {
          // window.localStorage.setItem(
          //   "CMS_STOKEN",
          //   response.sessionData.SESSION_TOKEN
          // );
          dispatch({
            type: "AUTH_DATA",
            auth_name: response.sessionData.NAME,
            auth_email_id: response.sessionData.EMAIL_ID,
            user_token: response.sessionData.SESSION_TOKEN,
          });
        } else {
          window.localStorage.removeItem("CMS_STOKEN");
        }
      });
  };

  useEffect(() => {
    if (props.employee_data.length > 0) {
      if (
        props.employee_data.some((val) => val.email_id == props.auth_email_id)
      ) {
        setEmpExist(true);
        let empRole = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        );
        console.log("mnsbdjab", empRole);
        setRole(empRole.role);
            dispatch({
              type: "AUTH_DATA",
              auth_name: empRole.employee_name,
              auth_email_id: empRole.email_id
             
            });
      } else {
        setEmpExist(false);
      }
    }
  }, [props.employee_data]);
  useEffect(() => {
    console.log("dncscsmcl", props.employee_data, props.auth_email_id);
  }, [empExist]);
  return (
    // <BrowserRouter>
    <>
      <Routes>
        {/* <Route path="/" element={<Dashboard />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contactCapture" element={<ContactCapture />} />
        <Route path="/scanText" element={<ScanText />} />
        <Route path="/searchPage" element={<SearchPage />} />
        <Route path="/contactDetails" element={<ContactDetails />} />
        <Route path="/companyView" element={<CompanyView />} />
        <Route path="/sendEmail" element={<SendEmail />} />
        <Route path="/mailHistory" element={<MailHistory />} />
        {role == "admin" && <Route path="/admin" element={<AdminPanel />} />}
        {role != "admin" && <Route path="/admin" element={<Page404 />} />}
        <Route path="/testing" element={<Testing />} />

        {/* {empExist == true && (
          <>
         
          </>
        )} */}
        {empExist == false && (
          <>
            <Route path="/" element={<Page404 />} />
            <Route path="/contactCapture" element={<Page404 />} />
            <Route path="/scanText" element={<Page404 />} />
            <Route path="/searchPage" element={<Page404 />} />
            <Route path="/contactDetails" element={<Page404 />} />
            <Route path="/companyView" element={<Page404 />} />
            <Route path="/sendEmail" element={<Page404 />} />
            <Route path="/mailHistory" element={<Page404 />} />
            <Route path="/admin" element={<Page404 />} />
            <Route path="/testing" element={<Page404 />} />
          </>
        )}
      </Routes>
      {/* {empExist == "NODATA" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Lottie
            // className="loading-lottie"
            style={{
              width: 250,
              height: 250,
            }}
            play
            //   speed={"1"}
            animationData={loadingAnime}
          />
        </Box>
      )} */}
    </>
    // </BrowserRouter>
  );
}

// export default App;
const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  auth_email_id: state.contactData.auth_email_id,
  // auth_email_id: "joyeeta.b@samishti.com",
  auth_name: state.contactData.auth_name,
  employee_data: state.contactData.employeeList,
});

export default connect(mapStateToProps, {
  ContactDataGetAction,
  MeetingDataGetAction,
  EmployeeDetailsGetAction,
})(App);
