import axios from "axios";
import getAccessToken from "../../constants/getAccessToken";
import clientData from "../../constants/clientData";
import { AxiosDefaultPort } from "../../constants/Axios";

export const EmployeeDetailsGetAction = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    const getSharepointData = (token) => {
      if (token == "ECONNREF") {
        reject("ECONNREF");
      } else {
        axios
          .post(
            // `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('employeeDetails')/items`,
            `${AxiosDefaultPort}apis/sharepoint/employeeDetailsGet`,
            { token }

            // {
            //   headers: {
            //     Authorization: "Bearer " + token,
            //   },
            // }
          )
          .then((response) => {
            let temp = [...response.data.value];
            temp.map((val) => {
              val.label = val.employee_name;
              val.value = val.ID;
            });
            dispatch({
              type: "EMPLOYEE_DETAILS_GET",
              payload: temp,
            });
            resolve("DATA_FOUND");
          })
          .catch((err) => {
            reject("ECONNREF");
          });
      }
    };

    getAccessToken(getSharepointData);
  });
};
