import React from "react";
import { TextField } from "@mui/material";
import Icons from "../../constants/Icons";
// import "./style.css";

function CustomInput({
  autoFocus,
  Placeholder,
  onChange,
  Value,
  Disabled,
  Type,
  Style,
  error,
  helperText,
  onBlur,
  isRequired,
  icon,
  onKeyDown,
}) {
  // if (
  //   Type == "date" &&
  //   Value != "" &&
  //   Value != "NA" &&
  //   Value != undefined &&
  //   Value != null
  // ) {
  //   console.log("jhsfjs", Value);

  //   Value = new Date(Value).toISOString().substr(0, 10);
  //   // Value = Value.replaceAll("/", "-");
  //   let dateStr = Value.split("-");
  //   Value = dateStr[2] + "-" + dateStr[1] + "-" + dateStr[0];
  //   console.log("jhsfjs1", Value);
  // }
  // console.log("jhsfjs", Value);

  return (
    <>
      <div className="group" style={{ backgroundColor: "#fdfdfe" }}>
        <div
          className="text-box-icon-style"
          style={{
            backgroundColor: isRequired ? "red" : "",
            // borderLeft:1
          }}
        />
        {icon && (
          <img
            style={{ marginLeft: 10, height: 20, width: 20 }}
            src={icon}
            alt=""
            className="img-responsive"
          />
        )}
        <input
          // autoFocus
          className={`text-box-style${error ? " text-error" : ""}`}
          placeholder={Placeholder}
          onChange={onChange}
          value={Value}
          disabled={Disabled}
          type={Type}
          style={{ ...Style }}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
      </div>
      <p className="helper-text">{helperText}</p>
    </>
  );
}

export default CustomInput;
