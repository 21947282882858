import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import Icons from "../../constants/Icons";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
  Toolbar,
  CssBaseline,
  AppBar,
  IconButton,
  Badge,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Modal,
} from "@mui/material";
import { FaArrowLeftLong } from "react-icons/fa6";
import { TiHome } from "react-icons/ti";
import { MdCampaign } from "react-icons/md";
import { MdPersonSearch } from "react-icons/md";
import { SiGoogleforms } from "react-icons/si";
import { RiAdminLine } from "react-icons/ri";

import { COLORS } from "../../constants/theme";
import useWindowSize from "./useWindowSize";
import MenuIcon from "@mui/icons-material/Menu";
import { EmployeeDetailsGetAction } from "../../Redux/Action/EmployeeDetailsGetAction";
import { connect, useDispatch } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CampaignIcon from "@mui/icons-material/Campaign";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import BetaCodeLogo from "../../assets/images/logo.svg"
const Navbar = (props, { headText }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const [mobileView, setMobileView] = useState(null);
  const [adminAccess, setAdminAccess] = useState(false);
  const [viewAccess, setViewAccess] = useState(true);

  useEffect(() => {
    if (windowSize.width < 600) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [windowSize]);

  useEffect(() => {
    if (props.employee_data) {
      if (
        props.employee_data.filter((val) => val.email_id == props.auth_email_id)
          .length > 0
      ) {
        let role = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;
        if (role == "admin") {
          setAdminAccess(true);
        }
        if (role == "view") {
          setViewAccess(false);
        }
      }
    }
  }, [props.employee_data]);

  const RoutesArray = [
    {
      label: "Home",
      icon: <HomeIcon />,
      path: "/dashboard",
      ACTIVE_KEY: "Dashboard",
      access: true,
    },
    {
      label: "Contact",
      icon: <PermContactCalendarIcon />,
      path: "/contactCapture",
      ACTIVE_KEY: "CONTACT_CAPTURE",
      access: viewAccess,
    },
    {
      label: "Search",
      icon: <PersonSearchIcon />,
      path: "/searchPage",
      ACTIVE_KEY: "SEARCH_CONTACT",
      access: true,
    },
    {
      label: "Campaign",
      icon: <CampaignIcon />,
      path: "/sendEmail",
      ACTIVE_KEY: "CREATE_CAMPAIGN",
      access: viewAccess,
    },
    {
      label: "Admin",
      icon: <AdminPanelSettingsIcon />,
      path: "/admin",
      ACTIVE_KEY: "ADMIN",
      access: adminAccess,
    },
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    // bgcolor: "background.paper",
    border: "none",
    // boxShadow: 24,
    // pt: 2,
    // px: 4,
    // pb: 3,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const SessionLogout = () => {
    window.localStorage.removeItem("CMS_STOKEN");
    dispatch({
      type: "AUTH_DATA",
      auth_name: "",
      auth_email_id: "",
      user_token: "",
    });
    navigate("/");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {/* <HideOnScroll> */}
      <AppBar
        sx={{
          backgroundColor: "#284b8c",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              // flexGrow: 1,
              // justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                // backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
                padding: 1,
              }}
            >
              <img
                src={BetaCodeLogo}
                alt=""
                className="img-responsive logo-img"
              />
            </Box>
            {mobileView == false && (
              <>
                {RoutesArray.map((val) => {
                  return (
                    <>
                      {val.access && (
                        <ListItem
                          // className="nav-bar-item"
                          disablePadding
                          sx={{
                            borderRadius: 4,
                            overflow: "hidden",
                            backgroundColor:
                              val.ACTIVE_KEY == props.headText ? "#1770E0" : "",
                          }}
                          onClick={() => navigate(val.path)}
                        >
                          <ListItemButton
                            sx={{
                              // width: 150,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {val.icon}
                            <Typography
                              sx={{
                                padding: 0.5,
                              }}
                            >
                              {val.label}
                            </Typography>

                            {/* <ListItemText
                              primary={val.label}
                              sx={{ textAlign: "center" }}
                              className="text-label"
                            /> */}
                          </ListItemButton>
                        </ListItem>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {mobileView == false && (
              <PowerSettingsNewIcon
                style={{
                  color: "white",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  SessionLogout();
                }}
              />
            )}
            {mobileView == true && (
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              >
                <MenuIcon />
              </Box>
            )}
          </Box>
          {/* <Button>Open modal</Button> */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style }}>
              {RoutesArray.map((val) => {
                return (
                  <ListItem
                    className="nav-bar-item"
                    disablePadding
                    sx={{
                      borderRadius: 4,
                      overflow: "hidden",
                      backgroundColor:
                        val.ACTIVE_KEY == headText ? "#1770E0" : "white",
                      mb: 2,
                    }}
                    onClick={() => navigate(val.path)}
                  >
                    <ListItemButton>
                      {val.icon}
                      <ListItemText
                        primary={val.label}
                        sx={{ textAlign: "center" }}
                        className="text-label"
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
              {/* <ChildModal /> */}
            </Box>
          </Modal>
        </Toolbar>
      </AppBar>
      {/* </HideOnScroll> */}
      <Toolbar />
      {/* <Container>{children}</Container> */}
    </React.Fragment>
  );
};

// export default ;

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  employee_data: state.contactData.employeeList,
});

export default connect(mapStateToProps, {})(Navbar);
