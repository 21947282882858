const grant_type = "client_credentials";
//Samishti
// const client_id =
//   "81ddd857-7787-49f1-bd20-be549222d151@4a65d39e-b3a8-402e-86b0-c1316ba372ba";
// const client_secret = "gvb9XTnWgymkNy15pgL0nYpYSJMQehBhy8He2ojOjUk=";
// const resource =
//   "00000003-0000-0ff1-ce00-000000000000/samishti.sharepoint.com@4a65d39e-b3a8-402e-86b0-c1316ba372ba";
// const tenant = "samishti.sharepoint.com";
// const site = "contactmanagementsystem_test";
// const resourceId = "4a65d39e-b3a8-402e-86b0-c1316ba372ba";
// --------------------------------------------

// KRC Production
// const client_id =
//   "6310569f-ec66-41b4-a1bc-cc31b3d6cd7c@adf953e1-1575-46ec-8398-5d4c7a540570";
// const client_secret = "sV84MaDmubwmYPlkK40dwJVcy3HYurYszlQYX64jCxE=";
// const resource =
//   "00000003-0000-0ff1-ce00-000000000000/kraheja.sharepoint.com@adf953e1-1575-46ec-8398-5d4c7a540570";
// const tenant = "kraheja.sharepoint.com";
// const site = "InorbitContactManagementSystem";
// const resourceId = "adf953e1-1575-46ec-8398-5d4c7a540570";
// -----------------
// KRC Testing
const client_id =
  "430e1bf2-7683-4e66-bdb3-144ec5d99a3a@adf953e1-1575-46ec-8398-5d4c7a540570";
const client_secret = "69y7F/LjfseX53Ykg2u6P5GyvHobbTV/Y1hYvCCEl9w=";
const resource =
  "00000003-0000-0ff1-ce00-000000000000/kraheja.sharepoint.com@adf953e1-1575-46ec-8398-5d4c7a540570";
const tenant = "kraheja.sharepoint.com";
const site = "InorbitContactManagementSystem_Dev";
const resourceId = "adf953e1-1575-46ec-8398-5d4c7a540570";

export default {
  grant_type,
  client_id,
  client_secret,
  resource,
  tenant,
  site,
  resourceId,
};
