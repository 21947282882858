"use client";
import {
  Grid,
  Box,
  Stack,
  TextField,
  Typography,
  Checkbox,
  Divider,
  CircularProgress,
  Button,
} from "@mui/material";
import React from "react";
import "./Login.css";
import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

import { Card } from "@mui/material";

import { useDispatch } from "react-redux";
import CustomInput from "../components/CustomInput/CustomInput";
import { AxiosDefaultPort } from "../constants/Axios";
import axios from "../components/Axios/axios";
import { useNavigate } from "react-router-dom";
import images from "../assets/images";

const Login = () => {
  const dispatch = useDispatch();

  // useEffect(()=>{

  // },[])
  const navigate = useNavigate();
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerEmail, setCustomerEmail] = useState("");
  const [CustomerContact, setCustomerContact] = useState("");
  const [CustomerConfirmPassword, setCustomerConfirmPassword] = useState("");
  const [CustomerPrivacyPolicy, setCustomerPrivacyPolicy] = useState(false);
  const [CustomerEmailErrorFlag, setCustomerEmailErrorFlag] = useState(false);
  const [OtpInputFlag, setOtpInputFlag] = useState(false);
  const [OtpErrorFlag, setOtpErrorFlag] = useState(false);
  const [OtpInput, setOtpInput] = useState("");
  const [TransactionStarted, setTransactionStarted] = useState(false);
  const [hostname, setHostname] = useState();

  const [
    CustomerCustomerPrivacyPolicyErrorFlag,
    setCustomerCustomerPrivacyPolicyErrorFlag,
  ] = useState(false);

  useEffect(() => {
    let currentUrl = window.location.href;
    let currentHost = currentUrl.split("//")[1].split(".")[0];
    console.log("jkaskksdfs", currentHost);
    setHostname(currentHost);

    const SessionToken = window.localStorage.getItem("CMS_STOKEN");
    console.log("ASDasdasdasd", SessionToken);
    if (SessionToken) {
      handleSessionVerify(currentHost, SessionToken);
    }
  }, []);

  const handleSessionVerify = (HostName, SessionID) => {
    axios
      .post(AxiosDefaultPort + "apis/Login/sessionTokenVerify", {
        HOSTNAME: HostName,
        SESSION_TOKEN: SessionID,
      })
      .then((response) => {
        console.log("ASdadasdas", response);
        if (response.otpMatch == true) {
          // window.localStorage.setItem(
          //   "CMS_STOKEN",
          //   response.sessionData.SESSION_TOKEN
          // );
          dispatch({
            type: "AUTH_DATA",
            auth_name: response.sessionData.NAME,
            auth_email_id: response.sessionData.EMAIL_ID,
            user_token: response.sessionData.SESSION_TOKEN,
          });

          navigate("/dashboard");
        } else {
          window.localStorage.removeItem("CMS_STOKEN");
        }
      });
  };
  const HandleLogin = (otp) => {
    setTransactionStarted(true);
    if (OtpInputFlag) {
      console.log("out", Number(otp), isNaN(Number(otp)));
      if (!isNaN(Number(otp)) && otp?.length == 6) {
        // console.log("100", Number(otp));
        axios
          .post(AxiosDefaultPort + "apis/Login/otpVerify", {
            EMAIL_ID: CustomerEmail,
            HOSTNAME: hostname,
            OTP: otp,
          })
          .then((response) => {
            console.log("kjsdfhkdsh khasbdjgasd", response);
            setTransactionStarted(false);
            setOtpInput("");
            if (response.otpMatch == true) {
              window.localStorage.setItem(
                "CMS_STOKEN",
                response.sessionData.SESSION_TOKEN
              );
              dispatch({
                type: "AUTH_DATA",
                auth_name: response.sessionData.NAME,
                auth_email_id: response.sessionData.EMAIL_ID,
                user_token: response.sessionData.SESSION_TOKEN,
              });

              navigate("/dashboard");
            } else {
              toast.error("Wrong OTP");
            }
          })
          .catch((err) => {
            setOtpInput("");
            setTransactionStarted(false);
            toast.error("Something went wrong");
          });
      } else {
        console.log("200", Number(OtpInput));
        console.log("201", OtpInput.length);

        setOtpErrorFlag(true);
      }
    } else {
      if (CustomerEmail == "") {
        setCustomerEmailErrorFlag(true);
      } else if (CustomerEmail != "") {
        axios
          .post(AxiosDefaultPort + "apis/Login/LoginOTPSend", {
            EMAIL_ID: CustomerEmail,
            IS_VERIFIED: false,
          })
          .then((response) => {
            console.log("jdsfksk", response);
            setTransactionStarted(false);
            if (response.otpSent == true) {
              toast.success("OTP sent");
              setOtpInputFlag(true);
            } else {
              toast.error("Account not found!");
            }
          })
          .catch((err) => {
            setTransactionStarted(false);
            toast.error("Something went wrong");
          });
      }
    }
  };

  const clearSignUpForm = () => {
    setCustomerPrivacyPolicy(false);
    setCustomerConfirmPassword("");
    setCustomerContact("");
    setCustomerEmail("");
    setCustomerName("");
  };

  // log out function to log the user out of google and set the profile array to null

  const generateAuthToken = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let token = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      token += characters.charAt(randomIndex);
    }

    return token;
  };
  return (
    <Box className="dashboard-container">
      <Toaster />
      <Grid container height={"100vh"}>
        <Grid item xs={12} md className="login" p={6} alignSelf={"center"}>
          <Box maxWidth={"390px"} mx="auto">
            <Stack mb={3} alignItems="start">
              <Typography
                gutterBottom
                variant="h6"
                style={{ fontWeight: 600, color: "#445279", fontSize: 30 }}
              >
                Login{" "}
              </Typography>

              {/* <Typography style={{ fontSize: 12, color: "#6e727b" }}>
                Not a Member?{" "}
                <h8
                  style={{
                    cursor: "pointer",
                    color: "#0956EB",
                  }}
                  onClick={() => {
                    navigate(CUSTOMER_SIGNUP);
                  }}
                >
                  Signup Now
                </h8>
              </Typography> */}
            </Stack>

            {/* Login With teams and Google Section */}
            {/* <Stack
              mb={2}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 20,
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            ></Stack> */}
            {/* <Divider textAlign="center" sx={{ color: "text.secondary", my: 2 }}>
              OR
            </Divider> */}
            <Card
              my={2}
              spacing={2.5}
              component="form"
              style={{
                padding: 10,
              }}
            >
              {OtpInputFlag && (
                <div className="p-3">
                  <p className="form-card-prompt">
                    Enter 6 digit OTP sent to your mail
                  </p>
                  <p className="mb-2">{CustomerEmail}</p>

                  <TextField
                    label={"OTP"}
                    value={OtpInput}
                    onChange={(e) => {
                      let numberOnly = e.target.value.replace(/\D/g, "");
                      setOtpErrorFlag(false);
                      if (numberOnly.length != 6) {
                        setOtpInput(numberOnly);
                      }
                      if (numberOnly.length == 6) {
                        setOtpInput(numberOnly);
                        HandleLogin(numberOnly);
                      }
                    }}
                    max={7}
                    style={{
                      width: "100%",
                    }}
                  />
                  {OtpErrorFlag && (
                    <p className="otp-error">Please enter a valid OTP</p>
                  )}
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <p className="call-again">
                      <span className="underlined">Send again</span>
                    </p>
                  </div>
                </div>
              )}
              {OtpInputFlag == false && (
                <div className="p-3 m-1">
                  <p className="form-card-prompt">Enter Your Email</p>
                  {/* <CustomInput
                    label={"Email"}
                    value={CustomerEmail}
                    onChange={(e) => {
                      setCustomerEmail(e.target.value);
                      setCustomerEmailErrorFlag(false);
                    }}
                    error={CustomerEmailErrorFlag}
                  /> */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    style={{
                      width: "100%",
                    }}
                    label={"Email"}
                    value={CustomerEmail}
                    onChange={(e) => {
                      setCustomerEmail(e.target.value);
                      setCustomerEmailErrorFlag(false);
                    }}
                    error={CustomerEmailErrorFlag}
                  />
                </div>
              )}
              <Stack my={3} spacing={2}>
                {OtpInputFlag && (
                  <Button
                    // color="secondary"
                    style={{
                      backgroundColor: "#262a50",
                      color: "#fff",
                    }}
                    onClick={() => HandleLogin(OtpInput)}
                  >
                    {TransactionStarted ? <CircularProgress /> : "Login"}
                  </Button>
                )}
                {!OtpInputFlag && (
                  <Button
                    // color=""
                    style={{
                      backgroundColor: "#262a50",
                      color: "#fff",
                    }}
                    onClick={() => HandleLogin(OtpInput)}
                  >
                    {TransactionStarted ? <CircularProgress /> : "Send OTP"}
                  </Button>
                )}
             
              </Stack>
            </Card>
          </Box>
        </Grid>
        <Grid
          item
          className="custLogin-gridR"
          xs={12}
          md={5.2}
          display={{ xs: "none", md: "flex" }}
          // sx={{ boxShadow: "2px 0 10px rgba(0,0,0,0.1)" }}
        >
          {/* <Lottie
            play
            //   speed={"1"}
            animationData={meetingLottie}
            style={{
              width: "70vh",
              // height: 250,
            }}
          /> */}
          <Box className="custLogin-container">
            {/* <img
              style={{ objectFit: "contain", width: 100, height: 100 }}
              //   src={IMAGES.illustration_login}
              src={images.loginPng}
              alt="illustration login"
            /> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
