const initialState = {
  contactDataList: [],
  meetingDataList: [],
  mailHistoryList: [],
  employeeList: [],
  ddOptionList: [],
  auth_email_id: "",
  auth_name: "",
};

const contactData = (state = initialState, action) => {
  switch (action.type) {
    case "CONTACT_DATA_GET":
      return { ...state, contactDataList: action.payload };
    case "MEETING_DATA_GET":
      return { ...state, meetingDataList: action.payload };
    case "MAIL_HISTORY_GET":
      return { ...state, mailHistoryList: action.payload };
    case "EMPLOYEE_DETAILS_GET":
      return { ...state, employeeList: action.payload };
    case "DROPDOWN_OPTIONS_GET":
      return { ...state, ddOptionList: action.payload };
    case "AUTH_DATA":
      return {
        ...state,
        auth_name: action.auth_name,
        auth_email_id: action.auth_email_id,
        user_token: action.user_token,
      };

    default:
      return state;
  }
};
export default contactData;
