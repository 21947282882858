import React, { useState, useEffect } from "react";
// import "./ContactCapture.css";
import "./AdminPanel.css";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
  Modal,
} from "@mui/material";
import CustomInput from "../components/CustomInput/CustomInput";
import Navbar from "../components/Navbar/Navbar";
import { COLORS } from "../constants/theme";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomDropdown from "../components/CustomDropDown/CustomDropDown";

import axios from "axios";
// import cogoToast from "cogo-toast";
import { ContactDataGetAction } from "../Redux/Action/ContactDataGetAction";
import { MeetingDataGetAction } from "../Redux/Action/MeetingDataGetAction";
import { EmployeeDetailsGetAction } from "../Redux/Action/EmployeeDetailsGetAction";
import { connect, useDispatch } from "react-redux";
import "react-html5-camera-photo/build/css/index.css";
import toast, { Toaster } from "react-hot-toast";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { TiDeleteOutline } from "react-icons/ti";
import getAccessToken from "../constants/getAccessToken";
import clientData from "../constants/clientData";
import Icons from "../constants/Icons";
import noData from "../assets/lottieFiles/noData.json";
import Lottie from "react-lottie-player";
import { AxiosDefaultPort } from "../constants/Axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function AdminPanel(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteEmpModal, setDeleteEmpModal] = useState(false);
  const [deletedVal, setDeletedVal] = useState({});

  let inputData = [
    {
      label: "Select Employee *",
      value: "",
      type: "dropDown",
      options: [],
      ID: "employee",
      emptyFlag: false,
    },
    {
      label: "Select Role *",
      value: "",
      type: "dropDown",
      options: [
        { label: "Admin", value: "admin" },
        { label: "Edit only", value: "edit" },
        // { label: "Delete only", value: "delete" },
        { label: "Edit & Delete", value: "editDelete" },
        { label: "View", value: "view" },
      ],
      ID: "role",
      emptyFlag: false,
    },
  ];
  let empInputData = [
    {
      label: "Employee Name *",
      value: "",
      type: "input",
      ID: "employee_name",
      emptyFlag: false,
      table: "contact",
      valid: true,
    },
    {
      label: "Employee Email *",
      value: "",
      type: "input",
      ID: "email_id",
      emptyFlag: false,
      table: "contact",
      valid: true,
    },

    {
      label: "Employee Role *",
      value: "",
      type: "dropDown",
      options: [
        { label: "Admin", value: "admin" },
        { label: "Edit only", value: "edit" },
        // { label: "Delete only", value: "delete" },
        { label: "Edit & Delete", value: "editDelete" },
        { label: "View", value: "view" },
      ],
      ID: "role",
      emptyFlag: false,
    },
  ];
  useEffect(() => {
    console.log("msfjhfjwh", props.contact_data);
  }, [props.contact_data]);
  const [inputList, setInputList] = useState([]);
  const [empInputList, setEmpInputList] = useState(empInputData);

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const [propEmpData, setPropEmpData] = useState([]);
  const [searchEmpData, setSearchEmpData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props.employee_data != undefined) {
      // alert("yes");
      inputData.find((val) => val.ID == "employee").options =
        props.employee_data;
      // setEmpDd(props.employee_data);
      setInputList(inputData);
      let tempPropEmp = [...props.employee_data];
      tempPropEmp = tempPropEmp.sort(function (a, b) {
        if (a.employee_name < b.employee_name) {
          return -1;
        }
        if (a.employee_name > b.employee_name) {
          return 1;
        }
        return 0;
      });

      setPropEmpData(tempPropEmp);
      setSearchEmpData(tempPropEmp);
    }
  }, [props.employee_data]);

  //-------------------------------------

  const submitEmployeeRole = () => {
    let temp = [...inputList];
    if (temp.some((val) => val.value == "" && val.emptyFlag != "NM")) {
      temp.map((val) => {
        if (val.value == "" && val.emptyFlag != "NM") {
          val.emptyFlag = true;
        }
      });
      setInputList(temp);
    } else {
      const postSharepointData = (token) => {
        let empDataId = inputList.find((val) => val.ID == "employee").value
          .value;
        console.log(
          "sjdjsdjsdj",
          inputList.find((val) => val.ID == "employee").value.value
        );
        let empRole = inputList.find((val) => val.ID == "role").value.value;
        // axios
        //   .post(
        //     `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('employeeDetails')/items/getbyid('${empDataId}')`,
        //     {
        //       __metadata: { type: "SP.Data.EmployeeDetailsListItem" },
        //       // designation: "asdas",
        //       role: empRole,
        //     },

        //     {
        //       headers: {
        //         "X-HTTP-Method": "MERGE",
        //         accept: "application/json;odata=verbose",
        //         "content-type": "application/json;odata=verbose",
        //         "If-Match": "*",
        //         Authorization: "Bearer " + token,
        //       },
        //     }
        //   )
        axios
          .post(`${AxiosDefaultPort}apis/sharepoint/empRoleUpdate`, {
            role: empRole,
            token,
            empDataId: empDataId,
          })
          .then((response) => {
            toast.success("Employee role updated");

            setInputList(inputData);
            let tempEmp = [...props.employee_data];
            let index = tempEmp.findIndex((val) => val.ID == empDataId);
            tempEmp[index].role = empRole;

            dispatch({
              type: "EMPLOYEE_DETAILS_GET",
              payload: tempEmp,
            });
            // setEditModal(false);
            // setMeetingList(meetingDetails);
          });
      };
      getAccessToken(postSharepointData);
    }
  };
  const submitEmployee = () => {
    let temp = [...empInputList];
    if (temp.some((val) => val.value == "" && val.emptyFlag != "NM")) {
      temp.map((val) => {
        if (val.value == "" && val.emptyFlag != "NM") {
          val.emptyFlag = true;
        }
      });
      setEmpInputList(temp);
    } else if (
      props.employee_data.some(
        (item) =>
          item.email_id ==
          empInputList.find((val) => val.ID == "email_id").value
      )
    ) {
      toast.error("Employee with same email already exists");
    } else {
      const postEmpSharepointData = (token) => {
        let empPayload = { Title: "" };
        empInputList.map((val) => {
          if (val.type == "input") {
            empPayload[val.ID] = val.value;
          } else if (val.type == "dropDown") {
            empPayload[val.ID] = val.value.value;
          }
        });
        // axios
        //   .post(
        //     `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('employeeDetails')/items`,
        //     {
        //       __metadata: { type: "SP.Data.EmployeeDetailsListItem" },
        //       ...empPayload,
        //     },
        //     {
        //       headers: {
        //         "Content-Type": "application/json;odata=verbose",
        //         Accept: "application/json;odata=verbose",
        //         Authorization: "Bearer " + token,
        //       },
        //     }
        //   )
        // ----------------------
        axios
          .post(`${AxiosDefaultPort}apis/sharepoint/employeeCreate`, {
            empPayload,
            token,
          })
          .then((resp1) => {
            if (resp1.data.INVALID_CHARS == true) {
              toast.error("Special characters not allowed");
            } else {
              toast.success("Employee Added");
              axios
                .post(
                  // `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('employeeDetails')/items`,
                  `${AxiosDefaultPort}apis/sharepoint/employeeDetailsGet`,
                  { token }
                )
                .then((response) => {
                  let temp = [...response.data.value];
                  temp.map((val) => {
                    val.label = val.employee_name;
                    val.value = val.ID;
                  });
                  dispatch({
                    type: "EMPLOYEE_DETAILS_GET",
                    payload: temp,
                  });
                });
            }
            setEmpInputList(empInputData);
          })
          .catch((err) => {
            console.log("Employee add error", err);
          });
      };
      getAccessToken(postEmpSharepointData);
    }
  };

  const deleteEmployee = () => {
    const deleteEmpFromList = (token) => {
      // const url = `https://${clientData.tenant}/sites/${clientData.site}/_api/Web/Lists/getbytitle('employeeDetails')/items/getbyid('${deletedVal.ID}')`;

      // axios
      //   .delete(
      //     url,

      //     {
      //       headers: {
      //         accept: "application/json;odata=verbose",
      //         "content-type": "application/json;odata=verbose",
      //         "If-Match": "*",
      //         Authorization: "Bearer " + token,
      //       },
      //     }
      //   )
      axios
        .post(`${AxiosDefaultPort}apis/sharepoint/employeeDelete`, {
          token,
          deleteID: deletedVal.ID,
        })
        .then((response) => {
          toast.success("Employee Deleted");
          setDeleteEmpModal(false);
          let tempEmp = [...props.employee_data];
          tempEmp = tempEmp.filter((item) => item.ID != deletedVal.ID);
          dispatch({ type: "EMPLOYEE_DETAILS_GET", payload: tempEmp });
        });
    };
    getAccessToken(deleteEmpFromList);
  };

  return (
    <Box className="dashboard-main-container">
      <div>
        <Toaster
          containerStyle={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20,
          }}
          gutter={24}
          toastOptions={{
            style: {
              border: "1px solid #grey",
              padding: "16px",
            },
            success: {
              style: {
                background: "#c4fcb1",
                opacity: 0.1,
              },
            },
          }}
        />
      </div>
      <Navbar headText="ADMIN" />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          {/* <h9 className="header-text">Contact Capture</h9> */}
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "start",
            }}
          >
            Admin Panel{" "}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="View Employee" {...a11yProps(0)} />
          <Tab label="Edit Role" {...a11yProps(1)} />
          <Tab label="Add employee" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {/* Item One */}
        <Box className="admin-mail-box">
          <CustomInput
            Placeholder={"Search by employee name, email or role"}
            icon={Icons.search}
            onChange={(e) => {
              let temp = [];
              if (e.target.value == "" || e.target.value == null) {
                setSearchEmpData(propEmpData);
              } else {
                propEmpData.map((val) => {
                  if (
                    val.employee_name
                      .toUpperCase()
                      .includes(e.target.value.toUpperCase()) ||
                    val.email_id
                      .toUpperCase()
                      .includes(e.target.value.toUpperCase()) ||
                    val.role
                      .toUpperCase()
                      .includes(e.target.value.toUpperCase())
                  ) {
                    temp.push(val);
                  }
                });
                if (temp.length == 0 && e.target.value) {
                  setNoDataFound(true);
                } else {
                  setNoDataFound(false);
                }

                setSearchEmpData(temp);
              }
            }}
          />
        </Box>
        <Card className="admin-table-card">
          <TableContainer
            className="admin-table-container"
            // sx={{
            //   maxHeight: "52vh",
            //   border: "1px solid #b7b7b7",
            // }}
          >
            <Table
              enableStickyHeader
              aria-label="sticky table"
              // sx={{ border: "1px solid #b7b7b7" }}
            >
              <TableHead
                sx={{
                  border: "1.5px solid #b7b7b7",
                }}
              >
                <tr
                  sx={{
                    "& .MuiTableCell-root": {
                      border: "none",
                      color: "#333",
                      fontWeight: 600,
                      fontSize: 17,
                      backgroundColor: "#ebe9d0",
                      textAlign: "center",
                      // width: 250,
                    },
                  }}
                  component={Paper}
                >
                  <th sx={{ maxWidth: 75 }}>Employee Name</th>
                  <th sx={{ maxWidth: 200 }}>Employee Email</th>
                  <th sx={{ maxWidth: 75 }}>Role</th>
                </tr>
              </TableHead>

              <TableBody className="admin-table-body">
                {searchEmpData &&
                  searchEmpData.map((val, index) => {
                    return (
                      <tr
                        className="Row"
                        sx={{
                          "&:hover": {
                            background: "#e5e6e7",
                          },
                          backgroundColor:
                            index % 2 == 0 ? "#f2f6fb" : "#fdfefe",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                          }}
                        >
                          <Box className="flex-row td-box">
                            <Tooltip title="Delete employee">
                              <Box
                                sx={{ cursor: "pointer", mb: -1 }}
                                onClick={() => {
                                  setDeleteEmpModal(true);
                                  setDeletedVal(val);
                                }}
                              >
                                <IconContext.Provider
                                  value={{ color: "#e63946" }}
                                >
                                  <TiDeleteOutline />
                                </IconContext.Provider>
                              </Box>
                            </Tooltip>
                            <Typography sx={{ textAlign: "center" }}>
                              {val.employee_name}
                            </Typography>
                            <Box sx={{ mb: -1 }}></Box>
                          </Box>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                          }}
                        >
                          <Box className="tdb-box">{val.email_id}</Box>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                            padding: 10,
                          }}
                        >
                          {val.role}
                        </td>
                        <Modal
                          open={deleteEmpModal}
                          onClose={() => {
                            setDeleteEmpModal(false);
                          }}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
                        >
                          <Box className="delete-modal-container">
                            <Box className="delete-modal-box">
                              <IconContext.Provider
                                value={{ size: 15, marginTop: 5 }}
                              >
                                <RxCross1
                                  onClick={() => {
                                    setDeleteEmpModal(false);
                                  }}
                                />
                              </IconContext.Provider>
                            </Box>

                            <Typography
                              sx={{
                                fontSize: 15,
                                color: COLORS.gray60,
                                ml: 2,
                              }}
                            >
                              Do you want to remove access of this employee from
                              this application?
                            </Typography>

                            <Box className="delete-btn-container">
                              <Box
                                className="delete-btn-box"
                                onClick={() => {
                                  deleteEmployee();
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: 15,
                                    color: COLORS.white,
                                  }}
                                >
                                  Delete
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                      </tr>
                    );
                  })}
              </TableBody>
            </Table>
            {noDataFound && (
              <Box className="no-data">
                <Lottie
                  play
                  //   speed={"1"}
                  animationData={noData}
                  style={{
                    width: 250,
                    height: 250,
                  }}
                />
                <Typography
                  sx={{ fontSize: 20, fontWeight: 500, color: "#b7beca" }}
                >
                  No Data Found
                </Typography>
              </Box>
            )}
          </TableContainer>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {/* Item Two */}
        <Box className="admin-container admin-container-padding">
          <Box className="admin-grid-container">
            {inputList.map((val, index) => {
              return (
                <div>
                  <Box className="admin-grid-item">
                    {val.type != "checkBox" && (
                      <Typography
                        sx={{
                          // color: "#284b8c",
                          color: COLORS.gray40,
                          fontWeight: "bold",
                          marginBottom: 0.5,
                          fontSize: 14,
                          mt: 1,
                        }}
                      >
                        {val.label}
                      </Typography>
                    )}

                    {val.type == "dropDown" && (
                      <Box sx={{ mr: "4%" }}>
                        <CustomDropdown
                          Label={val.label}
                          Options={val.options}
                          Value={val.value}
                          error={val.emptyFlag}
                          OnChange={(e) => {
                            let temp = [...inputList];
                            temp[index].value = e;
                            if (temp[index].ID == "employee") {
                              let roleIndex = temp.findIndex(
                                (val) => val.ID == "role"
                              );
                              let options = temp[roleIndex].options;
                              let empRole = props.employee_data.find(
                                (val) => val.ID == e.value
                              ).role;
                              temp[roleIndex].value = options.find(
                                (val) => val.value == empRole
                              );
                            }
                            if (
                              temp[index].value &&
                              temp[index].emptyFlag != "NM"
                            ) {
                              temp[index].emptyFlag = false;
                            } else if (temp[index].emptyFlag != "NM") {
                              temp[index].emptyFlag = true;
                            }
                            setInputList(temp);
                          }}
                        />
                      </Box>
                    )}

                    {val.emptyFlag == true && (
                      <Typography style={{ fontSize: 14, color: "#d32f2f" }}>
                        Please fill {val.label}
                      </Typography>
                    )}
                  </Box>
                </div>
              );
            })}
          </Box>
          <Box className="admin-btn-container">
            <CustomButton
              Label="Submit"
              // buttonColor="#3871a8"
              buttonColor="#40b3a2"
              style={{
                width: 100,
                height: 30,
                borderRadius: 5,
                marginRight: "0.5%",
              }}
              labelStyle={{
                color: "#fff",
                fontSize: 15,
              }}
              onClick={submitEmployeeRole}
            />
          </Box>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {/* Item Three */}
        {/* <Abcd
          empInputList={empInputList}
          setEmpInputList={setEmpInputList}
          submitEmployeeRole={submitEmployeeRole}
        /> */}
        <Box className="admin-container">
          <Box className="admin-grid-container">
            {empInputList.map((val, index) => {
              return (
                <div>
                  <Box className="grid-item">
                    {val.type != "checkBox" && (
                      <Typography
                        sx={{
                          // color: "#284b8c",
                          color: COLORS.gray40,
                          fontWeight: "bold",
                          marginBottom: 0.5,
                          fontSize: 14,
                          mt: 1,
                        }}
                      >
                        {val.label}
                      </Typography>
                    )}

                    {val.type == "dropDown" && (
                      <Box sx={{ mr: "4%" }}>
                        <CustomDropdown
                          Label={val.label}
                          Options={val.options}
                          Value={val.value}
                          error={val.emptyFlag}
                          OnChange={(e) => {
                            let temp = [...empInputList];
                            temp[index].value = e;
                            if (
                              temp[index].value &&
                              temp[index].emptyFlag != "NM"
                            ) {
                              temp[index].emptyFlag = false;
                            } else if (temp[index].emptyFlag != "NM") {
                              temp[index].emptyFlag = true;
                            }
                            setEmpInputList(temp);
                          }}
                        />
                      </Box>
                    )}
                    {val.type == "input" && (
                      <CustomInput
                        autoFocus={false}
                        Value={empInputList[index].value}
                        onChange={(e) => {
                          let temp = [...empInputList];

                          temp[index].value = e.target.value;

                          if (
                            temp[index].value == "" &&
                            temp[index].emptyFlag != "NM"
                          ) {
                            temp[index].emptyFlag = true;
                          } else if (temp[index].emptyFlag != "NM") {
                            temp[index].emptyFlag = false;
                          }
                          if (
                            temp[index].value &&
                            temp[index].emptyFlag != "NM"
                          ) {
                            temp[index].emptyFlag = false;
                          }
                          setEmpInputList(temp);
                        }}
                      />
                    )}

                    {val.emptyFlag == true && (
                      <Typography style={{ fontSize: 14, color: "#d32f2f" }}>
                        Please fill {val.label}
                      </Typography>
                    )}
                  </Box>
                </div>
              );
            })}
          </Box>
          <Box className="admin-btn-container">
            <CustomButton
              Label="Submit"
              // buttonColor="#3871a8"
              buttonColor="#40b3a2"
              style={{
                width: 100,
                height: 30,
                borderRadius: 5,
                marginRight: "0.5%",
              }}
              labelStyle={{
                color: "#fff",
                fontSize: 15,
              }}
              onClick={submitEmployee}
            />
          </Box>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  employee_data: state.contactData.employeeList,
});

export default connect(mapStateToProps, {
  ContactDataGetAction,
  MeetingDataGetAction,
  EmployeeDetailsGetAction,
})(AdminPanel);
